<template>
  <Page>
    <div class="home-page">
      <zui-header />
      <div class="content-box">
        <div class="bg">
          <img src="https://www.achencybe.com/img/pc/club/1.jpg">
          <div style="clear: both"></div>
        </div>
        <div class="content-text">
          <p v-for="(item,idx) in pList" :key="item"
             :class="{'font43':idx == 4,'padding43':(idx == 3 || idx == 8)}"
          >
            {{ item }}
          </p>
          <img src="https://www.achencybe.com/img/pc/footerDetail/5.jpg" style="width:160px">
        </div>

      </div>
      <zui-footer />
    </div>
  </Page>

</template>
<script>
import "../../less/home-page.less";
import "../../scss/ClubPage.scss";
import Page from "../../../components/Page";
import ZuiHeader from "../../../components/Header";
import ZuiFooter from "../../../components/Footer";

export default {
  components: { Page, ZuiHeader, ZuiFooter },
  data() {
    return {
      pList: [
        "喜欢交朋友？",
        "喜欢玩车？",
        "喜欢新鲜事物？",
        "来这儿就对了！",
        "WELCOME",
        "Achen&Cybe Club联手Car&Coffee",
        "将于每周在北京举行聚会",
        "期待你的加入",
        "关注Achen&Cybe官方公众号以获取活动信息"
      ]
    };
  },
  methods: {}

};
</script>