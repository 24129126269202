var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Page", [
    _c(
      "div",
      { staticClass: "home-page" },
      [
        _c("zui-header"),
        _c("div", { staticClass: "content-box" }, [
          _c("div", { staticClass: "bg" }, [
            _c("img", {
              attrs: { src: "https://www.achencybe.com/img/pc/club/1.jpg" },
            }),
            _c("div", { staticStyle: { clear: "both" } }),
          ]),
          _c(
            "div",
            { staticClass: "content-text" },
            [
              _vm._l(_vm.pList, function (item, idx) {
                return _c(
                  "p",
                  {
                    key: item,
                    class: {
                      font43: idx == 4,
                      padding43: idx == 3 || idx == 8,
                    },
                  },
                  [_vm._v(" " + _vm._s(item) + " ")]
                )
              }),
              _c("img", {
                staticStyle: { width: "160px" },
                attrs: {
                  src: "https://www.achencybe.com/img/pc/footerDetail/5.jpg",
                },
              }),
            ],
            2
          ),
        ]),
        _c("zui-footer"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }